import jQuery from 'jquery'
import $ from 'jquery';

$(document).ready(function(){

    $(function (){
        $('#buscador').click(function (e){
            $("#divB").toggle();
        })
        $('#buscadorD').click(function (e){
            $("#divBd").toggle();
        })

    });

    $(function (){
        $('#close-menu').click(function (e){
            $(".navbar-collapse").toggle();
        })
    });


    $(function (){
        $('#iiaGlobalpdf').hide();
        $('#iiaGlobaltitle').hide();

        $("#iiaColombiaButton").on( "click", function() {
            $('#iiaColombiapdf').show();
            $('#iiaColombiatitle').show();
            $('#iiaGlobaltitle').hide();
            $('#iiaGlobalpdf').hide();
        });
        $("#iiaGlobalButton").on( "click", function() {
            $('#iiaGlobalpdf').show();
            $('#iiaGlobaltitle').show();
            $('#iiaColombiatitle').hide();
            $('#iiaColombiapdf').hide();
        });

        $("#iiaColombiatitle2").on( "click", function() {
            $('#iiaColombiapdf').show();
            $('#iiaColombiatitle').hide();
            $('#iiaGlobaltitle').hide();
            $('#iiaGlobalpdf').hide();
            $('#iiaColombiatitle2').removeClass('p2').addClass('s1');
            $('#iiaGlobaltitle2').removeClass('s1').addClass('p2');
        });
        $("#iiaGlobaltitle2").on( "click", function() {
            $('#iiaGlobalpdf').show();
            $('#iiaGlobaltitle').hide();
            $('#iiaColombiatitle').hide();
            $('#iiaColombiapdf').hide();
            $('#iiaGlobaltitle2').removeClass('p2').addClass('s1');
            $('#iiaColombiatitle2').removeClass('s1').addClass('p2');
        });
    });
});


$(document).ready(function(){


    $("#punto1").hide();
    $("#hide1").hide();
    $("#show1").click(function(){
        $("#punto1").show();
        $("#hide1").show();
        $("#show1").hide();
    });
    $("#hide1").click(function(){
        $("#punto1").hide();
        $("#hide1").hide();
        $("#show1").show();
    });


    $("#punto2").hide();
    $("#hide2").hide();
    $("#show2").click(function(){
        $("#punto2").show();
        $("#hide2").show();
        $("#show2").hide();
    });
    $("#hide2").click(function(){
        $("#punto2").hide();
        $("#hide2").hide();
        $("#show2").show();
    });


    $("#punto3").hide();
    $("#hide3").hide();
    $("#show3").click(function(){
        $("#punto3").show();
        $("#hide3").show();
        $("#show3").hide();
    });
    $("#hide3").click(function(){
        $("#punto3").hide();
        $("#hide3").hide();
        $("#show3").show();
    });


    $("#punto4").hide();
    $("#hide4").hide();
    $("#show4").click(function(){
        $("#punto4").show();
        $("#hide4").show();
        $("#show4").hide();
    });
    $("#hide4").click(function(){
        $("#punto4").hide();
        $("#hide4").hide();
        $("#show4").show();
    });


    $("#punto5").hide();
    $("#hide5").hide();
    $("#show5").click(function(){
        $("#punto5").show();
        $("#hide5").show();
        $("#show5").hide();
    });
    $("#hide5").click(function(){
        $("#punto5").hide();
        $("#hide5").hide();
        $("#show5").show();
    });


    $("#punto6").hide();
    $("#hide6").hide();
    $("#show6").click(function(){
        $("#punto6").show();
        $("#hide6").show();
        $("#show6").hide();
    });
    $("#hide6").click(function(){
        $("#punto6").hide();
        $("#hide6").hide();
        $("#show6").show();
    });


    $("#punto7").hide();
    $("#hide7").hide();
    $("#show7").click(function(){
        $("#punto7").show();
        $("#hide7").show();
        $("#show7").hide();
    });
    $("#hide7").click(function(){
        $("#punto7").hide();
        $("#hide7").hide();
        $("#show7").show();
    });


    $("#punto8").hide();
    $("#hide8").hide();
    $("#show8").click(function(){
        $("#punto8").show();
        $("#hide8").show();
        $("#show8").hide();
    });
    $("#hide8").click(function(){
        $("#punto8").hide();
        $("#hide8").hide();
        $("#show8").show();
    });


    $("#punto9").hide();
    $("#hide9").hide();
    $("#show9").click(function(){
        $("#punto9").show();
        $("#hide9").show();
        $("#show9").hide();
    });
    $("#hide9").click(function(){
        $("#punto9").hide();
        $("#hide9").hide();
        $("#show9").show();
    });


    $("#punto10").hide();
    $("#hide10").hide();
    $("#show10").click(function(){
        $("#punto10").show();
        $("#hide10").show();
        $("#show10").hide();
    });
    $("#hide10").click(function(){
        $("#punto10").hide();
        $("#hide10").hide();
        $("#show10").show();
    });


    $("#punto11").hide();
    $("#hide11").hide();
    $("#show11").click(function(){
        $("#punto11").show();
        $("#hide11").show();
        $("#show11").hide();
    });
    $("#hide11").click(function(){
        $("#punto11").hide();
        $("#hide11").hide();
        $("#show11").show();
    });



});