// import jQuery from 'jquery'
// import $ from 'jquery';
//
// $("document").ready(function (){
//     var txt="0123456789abcdefghijklmnñopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
//     captcha = "";
//     for (var i=1; i<=6; i++){
//         captcha = captcha + txt.charAt(Math.floor(Math.random()*63));
//     }
//     $("#captcha").text(captcha);
//     $("#reload").click(function (){
//         location.reload(true);
//     })
//     $("#btn-captcha").click(function(){
//         var val1 = $("#codigo").val();
//         if(captcha == val1){
//             alert("Codigo valido");
//         }
//         else {
//             alert("Codigo incorrecto");
//         }
//     })
// })