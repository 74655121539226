import $ from 'jquery';


// $(document).ready(function(){
//     $(".dropdown-item").click(function(){
//         $(".menu").css("color", "#9ACA3C");
//     });
// });

$(function () {

    // Asignacion de estilos al menu

    const link1 = $(".menu-acerca");
    const link2 = $(".menu-membresia");
    const link3 = $(".menu-certificaciones");
    const link4 = $(".menu-capacitacion");
    const link5 = $(".menu-consultoria");
    const link6 = $(".menu-recursos");
    const link7 = $(".menu-global");

    var URLactual = location.href;
    var por = URLactual.split('/');
    var pos = por.length - 1;

    if (por[pos] === 'acercaIIA.php' ) {
        link1.addClass('p4');
    }

    if (por[pos] === 'membresia.php'  ||  por[pos] === 'aliados.php' ||  por[pos] === 'auditool.php') {
        link2.addClass('p4');
    }

    if (por[pos] === 'cia.php'  ||  por[pos] === 'qial.php'  ||  por[pos] === 'internalAuditPractitioner.php'  ||  por[pos] === 'crma.php'  ||  por[pos] === 'evaluacionCalidad.php' ) {
        link3.addClass('p4');
    }

    if (por[pos] === 'cursos.php'  ||  por[pos] === 'formularioCursos.php'  ||  por[pos] === 'curso1.php'  ||  por[pos] === 'curso2.php'  ||  por[pos] === 'curso3.php'
        ||  por[pos] === 'curso4.php'  ||  por[pos] === 'curso5.php'  ||  por[pos] === 'curso6.php'  ||  por[pos] === 'curso7.php'  ||  por[pos] === 'curso8.php'
        ||  por[pos] === 'curso9.php'  ||  por[pos] === 'curso10.php'  ||  por[pos] === 'curso11.php'  ||  por[pos] === 'curso12.php'  ||  por[pos] === 'curso13.php'
        ||  por[pos] === 'curso14.php'  ||  por[pos] === 'curso15.php'  ||  por[pos] === 'curso16.php' ) {
        link4.addClass('p4');
    }

    if (por[pos] === 'serviciosConsultoria.php') {
        link5.addClass('p4');
    }

    if (por[pos] === 'acercaProfesion.php'  ||  por[pos] === 'publicaciones.php'  ||  por[pos] === 'perspectivasInternas.php'
        ||  por[pos] === 'otrosRecursos.php' ||  por[pos] === 'blogs.php') {
        link6.addClass('p4');
    }

    if (por[pos] === 'iiaGlobal.php') {
        link7.addClass('p4');
    }


    $(window).on("load", function () {
        console.log('Hola');

        $('#recaptchaDos').click(function (e) {
            e.preventDefault();
            var $captcha = $( '#recaptcha' ),
                response = grecaptcha.getResponse();
            if (response.length === 0) {
                $( '.msg-error').text( "Es obligatorio marcar esta casilla" );
                if( !$captcha.hasClass( "error" ) ){
                    $captcha.addClass( "error" );
                }
            } else {
                $( '.msg-error' ).text('');
                $captcha.removeClass( "error" );
                // alert( 'reCAPTCHA marked' );
            }
        })
    });

    // $(".menu-acerca").click(function() {
    //     $(".menu-acerca").css("color", "#9ACA3C");
    // });

})