import $ from 'jquery';
/*
Components
*/
import './header/inicio';
import './search/buscador'
import 'bootstrap';
import './captcha/captcha';
import './tablesMiembros/tablesMiembros';

$(window).on("load", function () {
    $('.loading-body').fadeOut("slow");
});