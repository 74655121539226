import jQuery from 'jquery'
import $ from 'jquery';

$(document).ready(function(){
    $('#tableNoMiembros').hide();
    $('#tableEstudiante').hide();


    $("#miembros").on( "click", function() {
        $('#tableMiembros').show();
        $('#tableNoMiembros').hide();
        $('#tableEstudiante').hide();
        $('#miembros').addClass("cia").css("border-bottom", "3px solid #9F2743");
        $('#noMiembros').removeClass("cia").css("border-bottom", "3px solid #65656A");
        $('#estudiante').removeClass("cia").css("border-bottom", "3px solid #65656A");
    });
    $("#noMiembros").on( "click", function() {
        $('#tableNoMiembros').show();
        $('#tableMiembros').hide();
        $('#tableEstudiante').hide();
        $('#miembros').removeClass("cia").css("border-bottom", "3px solid #65656A");
        $('#noMiembros').addClass("cia").css("border-bottom", "3px solid #9F2743");
        $('#estudiante').removeClass("cia").css("border-bottom", "3px solid #65656A");
    });
    $("#estudiante").on( "click", function() {
        $('#tableEstudiante').show();
        $('#tableMiembros').hide();
        $('#tableNoMiembros').hide();
        $('#miembros').removeClass("cia").css("border-bottom", "3px solid #65656A");
        $('#noMiembros').removeClass("cia").css("border-bottom", "3px solid #65656A");
        $('#estudiante').addClass("cia").css("border-bottom", "3px solid #9F2743");
    });


    
    $("#miembrosCrma").on( "click", function() {
        $('#tableMiembros').show();
        $('#tableNoMiembros').hide();
        $('#tableEstudiante').hide();
        $('#miembrosCrma').addClass("crma").css("border-bottom", "3px solid #005DA8");
        $('#noMiembrosCrma').removeClass("crma").css("border-bottom", "3px solid #65656A");
    });
    $("#noMiembrosCrma").on( "click", function() {
        $('#tableNoMiembros').show();
        $('#tableMiembros').hide();
        $('#tableEstudiante').hide();
        $('#noMiembrosCrma').addClass("crma").css("border-bottom", "3px solid #005DA8");
        $('#miembrosCrma').removeClass("crma").css("border-bottom", "3px solid #65656A");
    });
});